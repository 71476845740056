<template>
	<div id="app">
		<!-- 头部&路由数层 -->
		<transition v-if="!$route.meta.NoNeedHome" name="fade" mode="out-in">
			<el-row class="container">
				<el-col :span="24" class="header">
					<el-col
						:span="10"
						class="logo collapsedLogo"
						:class="collapsed ? 'logo-collapse-width' : 'logo-width'"
					>
<!--						<img v-if="!collapsed" src="./assets/logo.png" alt="">-->
						<img v-if="!collapsed" style="height: 60px;width: 60px;margin-top: 15px;margin-left: 10px" src="./assets/60.png" alt="">
            <div style="font-size: 16px;;margin-top:10px;color: white">消防设施操作员<br>实训和鉴定系统</div>
						<!-- <div @click="toindex" style="text-align: center;">
							<div style="font-size: 16px;">{{ collapsed ? sysNameShort : sysName }}</div>
							<span style="font-size: 16px;">{{ collapsed ? sysNameShort1 : sysName1 }}</span>
						</div> -->
					</el-col>
					<el-col :span="10" class="logoban">
						<div
							:class="collapsed ? 'tools collapsed' : 'tools'"
							@click="collapse"
						>
							<i class="fa fa-align-justify"></i>
						</div>
						<el-breadcrumb separator="/" class="breadcrumb-inner collapsedLogo">
							<el-breadcrumb-item
								v-for="item in $route.matched"
								:key="item.path"
							>
								<span style=""> {{ item.name }}</span>
							</el-breadcrumb-item>
						</el-breadcrumb>
					</el-col>
					<el-col :span="4" class="userinfo">
						<el-dropdown trigger="hover" style="line-height: 58px;">
							<span class="el-dropdown-link userinfo-inner">									
								{{ sysUserName }}
								<img src="./assets/logoRight.png" height="128" width="128" />
								<el-badge :value="unreadCount" type="warning"/>
							</span>							
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="myNews">
									<el-badge :value="unreadCount" class="item" type="warning">
										我的消息
									</el-badge>
								</el-dropdown-item>
								<!-- <el-dropdown-item @click.native="Setting">修改密码</el-dropdown-item> -->
								<el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</el-col>
				</el-col>
				<el-col :span="24" class="main">
					<aside :class="collapsedClass">
						<el-scrollbar
							style="height: 100%; background: #00193c"
							class="scrollbar-handle"
						>
							<el-menu
								:default-active="$route.path"
								class="el-menu-vertical-demo"
								@open="handleopen"
								@close="handleclose"
								@select="handleselect"
								unique-opened
								router
								:collapse="isCollapse"
								background-color="#00193c"
								style="border-right: none"
								text-color="#fff"
								active-text-color="#ffd04b"
							>
								<sidebar
									v-for="(menu, index) in routes"
									@collaFa="collapseFa"
									:key="index"
									:item="menu"
								/>
							</el-menu>
						</el-scrollbar>
					</aside>
					<el-col
						:span="24"
						class="content-wrapper"
						:class="collapsed ? 'content-collapsed' : 'content-expanded'"
					>
						<div class="tags" v-if="showTags">
							<div id="tags-view-container" class="tags-view-container">
								<scroll-pane ref="scrollPane" class="tags-view-wrapper">
									<router-link
										v-for="(tag, index) in tagsList"
										ref="tag"
										:key="tag.path"
										:class="{ active: isActive(tag.path) }"
										:to="{
										path: tag.path,
										query: tag.query,
										fullPath: tag.fullPath,
										}"
										tag="span"
										@click.middle.native="closeTags(index)"
										class="tags-view-item"
									>
										{{ tag.title }}
										<span
										class="el-icon-close"
										@click.prevent.stop="closeTags(index)"
										/>
									</router-link>
								</scroll-pane>
							</div>
							<!-- 其他操作按钮 -->
							<div class="tags-close-box">
								<el-dropdown @command="handleTags">
									<el-button size="mini">
										<i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu size="small" slot="dropdown">
										<el-dropdown-item command="other">关闭其他</el-dropdown-item>
										<el-dropdown-item command="all">关闭所有</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
						<transition name="fade" mode="out-in">
							<div class="content-az">
								<router-view  v-if="isRouterAlive"></router-view>
							</div>
						</transition>
					</el-col>
				</el-col>
			</el-row>
		</transition>
		<!-- 页面层 -->
		<transition v-else name="fade" mode="out-in">
			<div class="content-az">
				<router-view></router-view>
			</div>
		</transition>
		<!-- 我的消息dialog层，根据需求可删除 -->
		<el-dialog
			title="我的消息列表"
			:class="newsDialogCss"
			:visible.sync="NewsVisible"
			v-model="NewsVisible"
			:close-on-click-modal="false"
			style="height: 100%;"
			class="myMessageDialog"
		>
			<div class="messageDialogChil">
				 <el-tabs tab-position="left" style="height: 100%;" v-model="messageTab" @tab-click="handleClick">
					<el-tab-pane label="任务消息" name="task">
						 <Message :templateType="messageTab" @closeDialog="closeDialog" ref="chilMessage" tabName="task"></Message>
					</el-tab-pane>
					<el-tab-pane label="催收消息" name="collection">
						<Message :templateType="messageTab" tabName="collection"></Message>
					</el-tab-pane>
					<el-tab-pane label="警示消息"  name="warning">
						<Message :templateType="messageTab" tabName="warning"></Message>
					</el-tab-pane>
				</el-tabs>		
			</div>
		</el-dialog>
		<!-- 灰度层，弹窗&dialog使用 -->
		<div
			class="v-modal"
			@click="closeZModalShadow"
			v-show="NewsVisible"
			tabindex="0"
			style="z-index: 2917"
		></div>
		<!-- 我的消息查看详情dialog层 -->
		<div v-if="messageDetailDialog" class="messageDetailDialog">
			<div class="closeDiaBtn" @click="closeDetailShadow">×</div>
			<div style="margin-top: 26px;">
				{{chilNodesData.content}}
			</div>
		</div>
		<!-- 灰度层，我的消息查看详情dialog层 -->
		<div
			class="v-modal"
			@click="closeDetailShadow"
			v-show="messageDetailDialog"
			tabindex="0"
			style="z-index: 3917"
		></div>
		<div
			class="v-modal"
			@click="collapse"
			v-show="SidebarVisible"
			tabindex="0"
			style="z-index: 2917"
		></div>
	</div>
</template>
<script>
import Sidebar from "./components/Sidebar";
import Message from "./components/Message";
import ScrollPane from "./components/ScrollPane";
import { getUserByToken } from "./api/api";
import router from './router/index'
export default {
	name: 'App',
	provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
		return {
			reload: this.reload                                              
		}
	},
	components: { Sidebar, ScrollPane,Message },
	data() {
		return {
			isRouterAlive: true,  //控制视图是否显示的变量
			sysName: "清大东方",
			sysName1: "消防设施操作员实训和鉴定系统",
			sysNameShort: "QDZN",
			sysNameShort1: "TSXT",
			NewsVisible: false,
			SidebarVisible: false,
			collapsed: false,
			zModalShadow: false,
			collapsedClass: "menu-expanded",
			ispc: false,
			sysUserName: "后台管理系统",
			newsDialogCss: "news-dialog",
			sysUserAvatar: "",
			isCollapse: false,
			tagsList: [],
			form: {
				name: "",
				region: "",
				date1: "",
				date2: "",
				delivery: false,
				type: [],
				resource: "",
				desc: "",
			},
			routes: [],
			unreadCount: 0,
			// tagNews: [
			// 	{ name: "首页", type: "" },
			// 	{ name: "用户管理", type: "" },
			// 	{ name: "组织机构管理", type: "success" },
			// 	{ name: "角色管理", type: "info" },
			// 	{ name: "菜单权限管理", type: "warning" },
			// 	{ name: "异常报告！", type: "danger" },
			// ],
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			affixTags: [],
			messageTab: "task",
			// 我的消息详情
			messageDetailDialog: false,
			chilNodesData: '',
		};
	},
	methods: {
		handleClick(tab, event){
			console.log(this.messageTab);
		},
		reload () {
			this.isRouterAlive = false;            //先关闭，
			this.$nextTick(function () {
				this.isRouterAlive = true;         //再打开
			}) 
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		closeZModalShadow() {
			this.NewsVisible = false;
		},
		closeDetailShadow() {
			this.messageDetailDialog = false;
			this.$refs.chilMessage.getNotificationWebsiteList()

		},
		closeDialog(e) {
			if(e) {
				this.chilNodesData = e
				this.messageDetailDialog = true;
			} else {
				this.NewsVisible = false;
			}
		},
		toindex() {
			this.$router.replace({
				path: "/",
			});
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		onSubmit() {
			console.log("submit!");
		},
		myNews() {
			this.newsDialogCss += " show ";
			this.NewsVisible = true;
		},
		handleopen() {
			//console.log('handleopen');
		},
		handleclose() {
			//console.log('handleclose');
		},

		handleselect: function (a, b) {},
		//退出登录
		logout: function () {
			var _this = this;
			this.$confirm("确认退出吗?", "提示", {
				//type: 'warning'
			}).then(() => {
				window.localStorage.removeItem("user");
				window.localStorage.removeItem("Token");
				window.localStorage.removeItem("TokenExpire");
				window.localStorage.removeItem("NavigationBar");
				window.localStorage.removeItem("refreshtime");
				window.localStorage.removeItem("router");
				sessionStorage.removeItem("Tags");
				global.antRouter = [];
				this.tagsList = [];
				this.routes = [];
				this.$store.commit("saveTagsData", "");
				_this.$router.push("/login");
				window.location.reload();
			})
			.catch(() => {});
		},
		//修改密码
		Setting: function () {
			var _this = this;
			this.$confirm("将要修改密码?", "提示", {
				//type: 'warning'
			}).then(() => {
				router.replace({
					path: "/callBackLogin",
					query: {redirect: router.currentRoute.fullPath}
				});
			})
			.catch(() => {});
		},
		//折叠导航栏
		collapse: function () {
			this.collapsed = !this.collapsed;
			if (this.ispc) {
				if (this.collapsed) {
					this.collapsedClass = "menu-collapsed";
				} else {
					this.collapsedClass = "menu-expanded";
				}
			} else {
				// mobile
				if (this.collapsed) {
					this.SidebarVisible = true;
					this.collapsedClass = "menu-collapsed-mobile";
				} else {
					this.SidebarVisible = false;
					this.collapsedClass = "menu-expanded-mobile";
				}
				this.collapsedClass += " mobile-ex ";
			}
			this.isCollapse = !this.isCollapse;
		},
		collapseFa: function () {
			if (!this.ispc) {
				this.collapse();
			}
		},
		showMenu(i, status) {
			this.$refs.menuCollapsed.getElementsByClassName(
				"submenu-hook-" + i
			)[0].style.display = status ? "block" : "none";
		},
		isActive(path) {
			return path === this.$route.fullPath;
		},
		// 关闭单个标签
		closeTags(index) {
			const delItem = this.tagsList.splice(index, 1)[0];
			const item = this.tagsList[index]
				? this.tagsList[index]
				: this.tagsList[index - 1];
			if (item) {
				delItem.path === this.$route.fullPath && this.$router.push(item.path);

				this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
			} else {
				this.$router.push(this.getPresentRouter());
			}
		},
		// 关闭全部标签
		closeAll() {
			this.tagsList = [];
			this.$router.push(this.getPresentRouter());
			sessionStorage.removeItem("Tags");
		},
		getPresentRouter() {
			var getRouter = JSON.parse(window.localStorage.router)
			if(getRouter[0].path == '/') {
				return getRouter[0].path
			} else {
				for(var i = 0; i < getRouter.length; i++) {
					if(getRouter[i].children.length > 0) {
						for(var a = 0; a < getRouter[i].children.length; a++) {
							if(getRouter[i].children[a].path) {
								return getRouter[i].children[a].path
							}
						}
					}
				}
			}
		},
		// 关闭其他标签
		closeOther() {
			const curItem = this.tagsList.filter((item) => {
				return item.path === this.$route.fullPath;
			});
			this.tagsList = curItem;
			sessionStorage.setItem("Tags", JSON.stringify(this.tagsList));
		},
		// 设置标签
		setTags(route) {
			if (!route.meta.NoTabPage && route.meta.title) {
				const isExist = this.tagsList.some((item) => {
					return item.path === route.fullPath;
				});
				!isExist &&
				this.tagsList.push({
					title: route.meta.title,
					path: route.fullPath,
					// name: route.matched[1].components.default.name
				});
			}
		},
		// 当关闭所有页面时隐藏
		handleTags(command) {
			command === "other" ? this.closeOther() : this.closeAll();
		},
		getUserInfoByToken(token) {
			var _this = this;
			var loginParams = { token: token };
			getUserByToken(loginParams).then((data) => {
				this.logining = false;
				if (!data.success) {
					_this.$message({
						message: data.message,
						type: "error",
					});
				} else {
					_this.$notify({
						type: "success",
						message: `欢迎管理员：${data.response.uRealName} ！`,
						duration: 3000,
					});
					_this.sysUserName = data.response.uRealName;
					window.localStorage.user = JSON.stringify(data.response);
				}
			});
		},
	},
	mounted() {
		var tags = sessionStorage.getItem("Tags")
		? JSON.parse(sessionStorage.getItem("Tags"))
		: [];
		if (tags && tags.length > 0) {
			this.tagsList = tags;
		}
		var NavigationBar = JSON.parse(
			window.localStorage.router ? window.localStorage.router : null
		);
		// var NavigationBar = global.antRouter;
		if (this.routes.length <= 0 && NavigationBar && NavigationBar.length >= 0) {
			this.routes = NavigationBar;
		}
		// 折叠菜单栏
		// this.collapse();
	},
	updated() {
		var user = JSON.parse(
			window.localStorage.user ? window.localStorage.user : null
		);
		if (user) {
			this.sysUserName = user.name+'-'+user.loginAccount || "后台管理系统";
			this.sysUserAvatar = user.avatar || "../assets/logo.png";

		}
		var NavigationBar = JSON.parse(
			window.localStorage.router ? window.localStorage.router : null
		);
		if (NavigationBar && NavigationBar.length >= 0) {
			if (
				this.routes.length <= 0 ||
				JSON.stringify(this.routes) != JSON.stringify(NavigationBar)
			) {
				this.routes = NavigationBar;
			}
		}
	},
	computed: {
		showTags() {
			if (this.tagsList.length > 1) {
				this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
			}
			return this.tagsList.length > 0;
		},
	},
	watch: {
		// 对router进行监听，每当访问router时，对tags的进行修改
		$route: async function (newValue, from) {
			this.setTags(newValue);
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				if (tags) {
					for (const tag of tags) {
						if (tag.to.path === this.$route.path) {
							this.$refs.scrollPane.moveToTarget(tag, tags);
							// when query is different then update
							// if (tag.to.fullPath !== this.$route.fullPath) {
							//     this.$store.dispatch('tagsView/updateVisitedView', this.$route)
							// }
							break;
						}
					}
				}
			});
		},
	},
	created() {
		// 第一次进入页面时，修改tag的值
		this.setTags(this.$route);
		this.ispc = window.screen.width > 680;
		if (this.ispc) {
			this.collapsedClass = "menu-expanded";
		} else {
			this.collapsedClass = "menu-expanded-mobile mobile-ex";
			this.collapsed = true;
			this.collapse();
		}
	},
};
</script>

<style lang="css">
@import "./style/home.css";
.el-breadcrumb {
  	line-height: 60px !important;
}
</style>

<style lang="stylus" scoped>
    .myMessageDialog>>>.el-dialog {
        height: 100%!important;
    }
    .myMessageDialog>>>.el-dialog__body {
        height: 100%!important;
    }
	.messageDialogChil {
		height: 100%!important;
	}
    .el-tabs el-tabs--left {
        height: 100%!important;
    }
	.messageDetailDialog {
		z-index: 3918;
		position: relative;
		background: #fff;
		width: 450px;
		top: 30%;
		left: 30%;
		min-height: 100px;
		padding: 10px 10px 20px;
	}
	.closeDiaBtn {
		width: 24px;
		height: 24px;
		font-size: 20px;
		background: #fff;
		text-align: center;
		line-height: 20px;
		border-radius: 50%;
		position: absolute;
		right: 10px;
		cursor: pointer;
	}
</style>

<style>
.menu-collapsed .el-icon-arrow-right:before {
  	display: none;
}
.tags {
	position: relative;
	overflow: hidden;
	border: 1px solid #f0f0f0;
	background: #f0f0f0;
}
.tags ul {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	display: none;
}
.tags-li {
	float: left;
	margin: 3px 5px 2px 3px;
	border-radius: 3px;
	font-size: 13px;
	overflow: hidden;
	height: 23px;
	line-height: 23px;
	border: 1px solid #e9eaec;
	background: #fff;
	padding: 3px 5px 4px 12px;
	vertical-align: middle;
	color: #666;
	-webkit-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}
.tags-li-icon {
  	cursor: pointer;
}
.tags-li:not(.active):hover {
  	background: #f8f8f8;
}
.tags-li-title {
	float: left;
	max-width: 80px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 5px;
	color: #666;
	text-decoration: none;
}
.tags-close-box {
	box-sizing: border-box;
	text-align: center;
	z-index: 10;
	float: right;
	margin-right: 1px;
	line-height: 2;
}
</style>
<style>
.news-dialog {
	background: #fff;
	z-index: 3000 !important;
	position: fixed;
	height: 100vh;
	width: 100%;
	max-width: 800px;
	top: 60px !important;
	left: 0 !important;
	-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	-webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
	-webkit-transform: translate(100%);
	z-index: 40000;
	left: auto !important;
	right: 0 !important;
	transform: translate(0);
}
.news-dialog .el-dialog {
	margin: auto !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	box-shadow: none;
	width: 100%;
}
.news-dialog.show {
  	transform: translate(0);
}
.tag-new {
	width: 100%;
	margin: 10px 0;
}
@media screen and (max-width: 680px) {
	.collapsedLogo {
		display: none;
	}
	.el-dialog {
		width: 90% !important;
	}
	.content-expanded {
		max-width: 100% !important;
		max-height: calc(100% - 60px);
	}
	.mobile-ex {
		background: #fff;
		z-index: 3000;
		position: fixed;
		height: 100vh;
		width: 100%;
		max-width: 260px;
		top: 0;
		left: 0;
		-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
		-webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
		transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
		-webkit-transform: translate(100%);
		z-index: 40000;
		left: auto;
		right: 0;
		transform: translate(100%);
	}
	.mobile-ex.menu-collapsed-mobile {
		transform: translate(0);
	}
	.el-menu--collapse {
		width: 100% !important;
	}
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner,
	.el-cascader.el-cascader--medium {
		width: 100% !important;
	}
	.toolbar.roles {
		width: 100% !important;
	}
	.toolbar.perms {
		width: 800px !important;
	}
	.toolbar.perms .box-card {
		width: 100% !important;
	}
	.login-container {
		width: 300px !important;
	}
	.content-wrapper .tags {
		margin: 0px;
		padding: 0px;
	}
	.activeuser {
		display: none !important;
	}
}
</style>

<style>
.tags-view-container {
	height: 34px;
	width: calc(100% - 60px);
	/*background: #fff;*/
	/*border-bottom: 1px solid #d8dce5;*/
	/*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);*/
	float: left;
}
.tags-view-container .tags-view-wrapper .tags-view-item {
	display: inline-block;
	position: relative;
	cursor: pointer;
	height: 26px;
	line-height: 26px;
	border: 1px solid #d8dce5;
	color: #495060;
	background: #fff;
	padding: 0 8px;
	font-size: 12px;
	margin-left: 5px;
	margin-top: 4px;
}
.tags-view-container .tags-view-wrapper .tags-view-item:first-of-type {
  	margin-left: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item:last-of-type {
  	margin-right: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item.active::before {
	content: "";
	background: #2d8cf0;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: relative;
	margin-right: 2px;
}
.tags-view-container .contextmenu {
	margin: 0;
	background: #fff;
	z-index: 3000;
	position: absolute;
	list-style-type: none;
	padding: 5px 0;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #333;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.tags-view-container .contextmenu li {
	margin: 0;
	padding: 7px 16px;
	cursor: pointer;
}
.tags-view-container .contextmenu li:hover {
  	background: #eee;
}
</style>

<style>
.tags-view-wrapper .tags-view-item .el-icon-close {
	width: 16px;
	height: 16px;
	vertical-align: 2px;
	border-radius: 50%;
	text-align: center;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transform-origin: 100% 50%;
}
.tags-view-wrapper .tags-view-item .el-icon-close:before {
	transform: scale(0.6);
	display: inline-block;
	vertical-align: -3px;
}
.tags-view-wrapper .tags-view-item .el-icon-close:hover {
	background-color: #ef2b74;
	color: #fff;
}
.logo-collapse-width div{
  	font-size: 18px!important;
}
.logo-width{
	display: flex;
	align-items: center;
}
</style>
