import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import CallBackLogin from '../views/CallBackLogin.vue'
import Welcome from '../views/Welcome'
import NoPage from '../views/404'
import Layout from "../views/Layout/Layout";
import Router from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const createRouter = () => new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/404', component: NoPage, name: 'NoPage',
            meta: {
                title: 'NoPage',
                requireAuth: false,
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        }, 
        {
            path: '/',
            component: Welcome,
            name: '工作台主页面',
            iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                title: '工作台主页面',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '登录',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/callBackLogin',
            component: CallBackLogin,
            name: 'callBackLogin',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '修改密码',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
    ]
})
const router = createRouter()
export function filterAsyncRouter(asyncRouterMap) {
    //注意这里的 asyncRouterMap 是一个数组
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.path && !route.isButton) {
            if (route.path === '/' || route.path === '-') {//Layout组件特殊处理
                route.component = Layout
            } else {
                try {
                    route.component = _import(route.path.replace('/:id',''))
                } catch (e) {
                    try {
                        route.component = () => import('@/views' + route.path.replace('/:id','') + '.vue');
                    } catch (error) {
                        console.info('%c 当前路由 ' + route.path.replace('/:id','') + '.vue 不存在，因此如法导入组件，请检查接口数据和组件是否匹配，并重新登录，清空缓存!', "color:red")
                    }
                }
            }
        }
        if (route.children && route.children.length && !route.isButton) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
    return accessedRouters
}
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}
router.$addRoutes = (params) => {
    // var f = item => {
    //     if (item['children']) {
    //         item['children'] = item['children'].filter(f);
    //         return true;
    //     } else if (item['isButton']) {
    //         return item['isButton']===false;
    //     }  else {
    //         return true;
    //     }
    // }
    // var params = params.filter(f);
    params.forEach(item => {
        if(!item.isButton) {
            if(item.children && item.children.length > 0) {
                var newNArr = []
                item.children.forEach(n => {
                    if(!n.isButton) {
                        if(n.children.length > 0) {
                            var newArr = []
                            n.children.forEach(o => {
                                if(!o.isButton) {
                                    newArr.push(o)
                                }
                            })
                            n.children = newArr
                        }
                        newNArr.push(n)
                    }
                    item.children = newNArr
                })
            }
        }
    });
    router.addRoutes(params)
}
export default router
